import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import React from "react";
import "../css/app.scss";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { MantineProvider, createTheme } from "@mantine/core";
const topLevelDomain = window.location.hostname.split(".").pop();

Sentry.init({
    dsn: "https://0e579d4dc1cdac148e7f76dc7bc4eb99@o369532.ingest.us.sentry.io/4507570742231040",
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    enabled: topLevelDomain !== "test",
});
const container = document.getElementById("app");
const root = createRoot(container!);

const theme = createTheme({
    fontFamily: "Poppins",
    radius: { default: "4px" },
});

createInertiaApp({
    progress: {
        color: "#f3ab1f",
    },
    title: (title) => (title ? `${title} - Deluxe Hotels` : "Deluxe Hotels"),
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx")
        ),
    setup({ App, props }) {
        root.render(
            <MantineProvider theme={theme}>
                <App {...props} />
            </MantineProvider>
        );
    },
});
